import React from 'react';
import {NavLink} from 'react-router-dom';

export default (props)=>{
    let errorMessage = props.error ? <span className="help-block">
    <strong className="text-danger font-weight-bold">{props.errorDetails ? props.errorDetails.details:""}</strong>
    </span> : null;

    let errorTypeMessage = !props.apiResponse ? <span className="help-block">
    <strong className="text-danger font-weight-bold">Something went wrong please try again</strong>
    </span> : null;

    let deviceRegErrorMessage = props.deviceRegError ? <span className="help-block">
    <strong className="text-danger font-weight-bold">{props.deviceRegError}</strong>
    </span> : null;
console.log("Props: ", props);
    return(
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-6 col-md-8">
                    <div className="card">
                        <div className="card-body p-lg-5">
                            <h2 className="weight600 mt-3- mb-4 text-center">One Time Password</h2>
                            <form className="p-lg-3" onSubmit={(e)=>{e.preventDefault();props.verify()}}>
                                <div className={`form-group`}>
                                    <label>One Time Password</label>
                                    <div className="input-group-field">
                                        <React.Fragment>
                                            {props.changePhoneNumber == true ? null : <i className="si-amount"/>}
                                            <input value={props.otp} required={true}  onChange={(e)=>{props.stateChangeHandler(e, 'otp')}} type="text" className="form-control" placeholder="One Time Password"/>
                                            {errorMessage}
                                            {errorTypeMessage}
                                            {deviceRegErrorMessage}
                                        </React.Fragment>
                                    </div>
                                </div>
                                    <div className="text-center">
                                        <button  type="submit" className="btn btn-primary btn-block">CONTINUE</button>
                                        <div className="py-2"/>
                                        { props.changePhoneNumber == true ? null : <NavLink to={"/sign-in"}>
                                            <button type="button" className="btn btn-outline-primary btn-block">Sign In</button>
                                        </NavLink>
                                        }
                                        {
                                            props.changePhoneNumber == true ? 
                                            <a href="javascript:;" onClick={() => {props.resendOtpHandler()}}>Send New Code</a>
                                            : null                                         
                                        }
                                    </div>
                                    <div className="col-lg-6 col-10">
                                    </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}