import React from 'react'
import  './receipt.css';
import dateFormat, { masks } from "dateformat";
const dotlines = require('./images/dotlines.svg');
const sohoj = require('./images/sohoj.svg');
const contentBorder = require('./images/content-border.svg');

export default function Receipt(props) {
    let errorMessage = props.error ? <span className="help-block">
    <strong className="text-danger font-weight-bold">Something went wrong</strong>
    </span> : null;

    let transactionDate = new Date(props.reportData.Balance_Received_Time);
    transactionDate = dateFormat(transactionDate, "dS mmmm, yyyy, h:MM:ss TT");
    
    let transAmount = parseInt(props.reportData.Received_Amount);
    let transAmountLen = transAmount.toString().length;
    let chargeAmount = parseInt(props.reportData.Received_Amount * .025);
    let chargeAmountLen = chargeAmount.toString().length;
    let totalAmount = parseInt(props.reportData.Received_Amount + (props.reportData.Received_Amount * .025))
    let totalAmountLen = totalAmount.toString().length;

    return (
        <div style={{padding: "70px 0 50px",margin: 0, color: "#000000", fontFamily : "Poppins", fontSize: "14px", lineHeight: "1.15", background: "#ffffff"}}>
            <div className="container-receipt">
                    <div className="header-receipt">
                        <div className="address-receipt">
                            <img src={dotlines} alt="dotlines" />
                            <h4>Dotlines Sdn Bhd</h4>
                            <p>
                                Unit 26-03, Tower A, The Vertical Business Suites, Bangsar South City,<br/>
                                No 8 Jalan Kerinchi, 59200 Kuala Lumpur.<br/>
                                Service Tax ID: W10-1809-32001652.<br/>
                                Phone No: 03-23919006.<br/>
                                Fax No: 03-27120046.
                            </p>
                        </div>
                        <div className="sohojlogo">
                            <img src={sohoj} alt="dotlines" />
                        </div>
                    </div>
                    <div className="content-receipt">
                        <div className="content-box-receipt">
                            <h4>Receipt</h4>
                            <ul>
                                <li><span>Receipt No:</span> {props.reportData.transactionId}</li>
                                <li><span>Status:</span> {props.reportData.Status}</li>
                                <li><span>Bank Ref No:</span> {props.reportData.salesOrderBankRefId}</li>
                                <li><span>Transaction Date:</span> {transactionDate}</li>
                                <li><span>Amount:</span> {(props.reportData.Received_Amount).toPrecision(transAmountLen+2)}</li>
                                {/* <li><span>Amount:</span> {(props.reportData.Received_Amount * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</li> */}
                                <li><span>Service Charge (2.5%)</span> {(props.reportData.Received_Amount * .025).toPrecision(chargeAmountLen+2)}</li>
                            </ul>
                        </div>
                        <div className="content-box-receipt">
                            <h4>Total Amount: {(props.reportData.Received_Amount + (props.reportData.Received_Amount * .025)).toPrecision(totalAmountLen+2)}</h4>
                            <ul>
                                <li><span>User ID: </span> {props.reportData.Received_By_ID}</li>
                                <li><span>User Full Name: </span> {props.reportData.Received_User_Full_Name} </li>
                                <li><span>User Mobile No:</span> {props.reportData.Received_User_MobileNo}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-receipt">
                        <h3>Thank you!</h3>
                        <p>Note: This receipt is computer generated and no signature is required</p>
                        <p>Hotline Number: <a href="tel:+60163600453">+60163600453</a>, <a
                                href="tel:+8801404460149">+8801404460149</a></p>
                    </div>
                    <div>
                        {errorMessage}
                    </div>
            </div>
        </div> 
            
    )
}
