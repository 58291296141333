import React from 'react';
import {Link} from 'react-router-dom';
export default (props)=>{
    return(
        <div className="branding-wrap">

            <div className="navbar-brand">
                <Link to="/">
                    <img src={props.Logo} alt="sohoj"/>
                </Link>
            </div>
        </div>
    )
}