import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Switch, Route } from "react-router";
import Loadable from 'react-loadable';
import axiosAuth from './axiosAuth';
import apiRoute from './apiRoute';
import PrivateRouteAuth from './hoc/PrivateRouteAuth';
import PrivateRouteNotAuth from './hoc/PrivateRouteNotAuth';
import Auxi from "./hoc/Auxi";
import './css/css.css';
import UserDeviceListMenu from './Component/NotAuth/UserDevice/UserDeviceListMenu';
import UserDeviceRegistrationManager from './Container/NotAuth/UserDeviceRegistrationManager';
import getSession from './Component/UI/Session/getSession';
import Invoice from './Component/NotAuth/Receipt/Invoice';






const Loading = () => {
    return <Auxi>
        <div className="row justify-content-center">
            <div className="col-xl-6 col-md-8">
                <div className="loader" style={{ top: '100%' }} />
                <h4 style={{ "textAlign": "center" }}>this may take a few seconds....</h4>
            </div>
        </div>
    </Auxi>;
};


/*================================================Common Module ==============================================================================*/
/*import PrivacyPolicyManager from './Container/NotAuth/PrivacyPolicyManager';
import TermsConditionManager from './Container/NotAuth/TermsConditionManager';*/

const PrivacyPolicyManager = Loadable({
    loader: () => import('./Container/NotAuth/PrivacyPolicyManager'),
    delay: 60,
    loading: Loading
});

const TermsConditionManager = Loadable({
    loader: () => import('./Container/NotAuth/TermsConditionManager'),
    delay: 60,
    loading: Loading
});


/*===============================================Not Auth Module =============================================================================*/
/*import RechargeInquiryManager from './Container/NotAuth/RechargeInquiryManager';
import SignInManger from './Container/NotAuth/SignInManger';
import SignOutManager from './Container/NotAuth/SignOutManager';*/

const RechargeInquiryManager = Loadable({
    loader: () => import('./Container/NotAuth/RechargeInquiryManager'),
    delay: 60,
    loading: Loading
});
const SignInManger = Loadable({
    loader: () => import('./Container/NotAuth/SignInManger'),
    delay: 60,
    loading: Loading
});
const SignOutManager = Loadable({
    loader: () => import('./Container/NotAuth/SignOutManager'),
    delay: 60,
    loading: Loading
});



const SignUpManager = Loadable({
    loader: () => import('./Container/NotAuth/SignUpManager'),
    delay: 60,
    loading: Loading
});
const SupportManager = Loadable({
    loader: () => import('./Container/NotAuth/SupportManager'),
    delay: 60,
    loading: Loading
});
const MessageManager = Loadable({
    loader: () => import('./Container/NotAuth/MessageManager'),
    delay: 60,
    loading: Loading
});
const ForgetPasswordManager = Loadable({
    loader: () => import('./Container/NotAuth/ForgetPasswordManager'),
    delay: 60,
    loading: Loading
});
const ChangePasswordManager = Loadable({
    loader: () => import('./Container/NotAuth/ChangePasswordManager'),
    delay: 60,
    loading: Loading
});
const OtpVerificationManager = Loadable({
    loader: () => import('./Container/NotAuth/OtpVerificationManager'),
    delay: 60,
    loading: Loading
});



/*================================================Dashboard Module ==========================================================================*/
const DashboardManager = Loadable({
    loader: () => import('./Container/Auth/DashboardManager'),
    delay: 60, loading: Loading
});

const AdminDashboard = Loadable({
    loader: () => import('./Container/Auth/AdminDashboardManager'),
    delay: 60, loading: Loading
});



/*================================================Service Module ==========================================================================*/
const TopUpManager = Loadable({
    loader: () => import('./Container/Auth/Services/TopUpManager'),
    delay: 60, loading: Loading
});

const InternetBalanceManager = Loadable({
    loader: () => import('./Container/Auth/Services/InternetBalanceManager'),
    delay: 60, loading: Loading
});

const SohojRechargeManager = Loadable({
    loader: () => import('./Container/Auth/Services/SohojRechargeManager'),
    delay: 60, loading: Loading
});

const FundTransferManager = Loadable({
    loader: () => import('./Container/Auth/Services/FundTransferManager'),
    delay: 60, loading: Loading
});
const BulkUploadManager = Loadable({
    loader: () => import('./Container/Auth/Services/BulkUploadManager'),
    delay: 60, loading: Loading
});

const PinGenerateManager = Loadable({
    loader: () => import('./Container/Auth/Services/PinGenerateManager'),
    delay: 60, loading: Loading
});

const CashRechargeManager = Loadable({
    loader: () => import('./Container/Auth/Services/CashRechargeManager'),
    delay: 60, loading: Loading
});

const PurchaseBalanceManager = Loadable({
    loader: () => import('./Container/Auth/Services/PurchaseBalanceManager'),
    delay: 60, loading: Loading
});
const BillPaymentManager = Loadable({
    loader: () => import('./Container/Auth/Services/BillPaymentManager'),
    delay: 60, loading: Loading
});


/*================================================Report Module ==========================================================================*/
const MyReportManager = Loadable({
    loader: () => import('./Container/Auth/Report/MyReportManager'),
    delay: 60, loading: Loading
});
const DepositReportManager = Loadable({
    loader: () => import('./Container/Auth/Report/DepositReportManager'),
    delay: 60, loading: Loading
});
const ExecutionReportManager = Loadable({
    loader: () => import('./Container/Auth/Report/ExecutionReportManager'),
    delay: 60, loading: Loading
});
const SalesReportManager = Loadable({
    loader: () => import('./Container/Auth/Report/SalesReportManager'),
    delay: 60, loading: Loading
});
const salesFundTransferRequests = Loadable({
    loader: () => import('./Container/Auth/Report/SalesFundTransferRequestsManager'),
    delay: 60, loading: Loading
});
const CardReportManager = Loadable({
    loader: () => import('./Container/Auth/Report/CardReportManager'),
    delay: 60, loading: Loading
});


/*================================================Profile Module ==========================================================================*/
const ProfileChangePassword = Loadable({
    loader: () => import('./Container/Auth/Profile/ProfileChangePassword'),
    delay: 60, loading: Loading
});
const ProfileChangeNotification = Loadable({
    loader: () => import('./Container/Auth/Profile/ProfileChangeNotification'),
    delay: 60, loading: Loading
});
const ProfileUpdatePic = Loadable({
    loader: () => import('./Container/Auth/Profile/ProfileUpdatePic'),
    delay: 60, loading: Loading
});


/*================================================User Module ==========================================================================*/
const UserManagement = Loadable({
    loader: () => import('./Container/Auth/User/UserManagement'),
    delay: 60, loading: Loading
});
const UserCreateManager = Loadable({
    loader: () => import('./Container/Auth/User/UserCreateManager'),
    delay: 60, loading: Loading
});

const RoleManager = Loadable({
    loader: () => import('./Container/Auth/User/RoleManager'),
    delay: 60, loading: Loading
});
const RoleSettingsManager = Loadable({
    loader: () => import('./Container/Auth/User/RoleSettingsManager'),
    delay: 60, loading: Loading
});
const SalesRouteManager = Loadable({
    loader: () => import('./Container/Auth/User/SalesRouteManager'),
    delay: 60, loading: Loading
});
const CashRechargeAgent = Loadable({
    loader: () => import('./Container/Auth/User/CashRechargeAgentManager'),
    delay: 60, loading: Loading
});


/*================================================Sales Module ==========================================================================*/
const salesCardInvoice = Loadable({
    loader: () => import('./Container/Auth/SalesDeposit/SalesCardInvoiceManager'),
    delay: 60, loading: Loading
});
const salesDeposits = Loadable({
    loader: () => import('./Container/Auth/SalesDeposit/SalesDepositsManager'),
    delay: 60, loading: Loading
});
const CardTransferManager = Loadable({
    loader: () => import('./Container/Auth/SalesDeposit/CardTransferManager'),
    delay: 60, loading: Loading
});


/*================================================Lot Card Module ==========================================================================*/
const CardGenerateManager = Loadable({
    loader: () => import('./Container/Auth/Lot_Card/CardGenerateManager'),
    delay: 60, loading: Loading
});
const LotGenerateManager = Loadable({
    loader: () => import('./Container/Auth/Lot_Card/LotGenerateManager'),
    delay: 60, loading: Loading
});
const LotActivationManager = Loadable({
    loader: () => import('./Container/Auth/Lot_Card/LotActivationManager'),
    delay: 60, loading: Loading
});
const LotProductRelationManager = Loadable({
    loader: () => import('./Container/Auth/Lot_Card/LotProductRelationManager'),
    delay: 60, loading: Loading
});



/*================================================Configuration Module ==========================================================================*/
const UserCommissionManagement = Loadable({
    loader: () => import('./Container/Auth/Configuration/UserCommissionManagement'),
    delay: 60, loading: Loading
});
const UserCurrency = Loadable({
    loader: () => import('./Container/Auth/Configuration/UserCurrencyManager'),
    delay: 60, loading: Loading
});
const UserConfiguration = Loadable({
    loader: () => import('./Container/Auth/Configuration/UserConfigurationManager'),
    delay: 60, loading: Loading
});
const AddBalanceManager = Loadable({
    loader: () => import('./Container/Auth/Configuration/AddBalanceManager'),
    delay: 60, loading: Loading
});


/*================================================Reseller Module ==========================================================================*/
const ResellerManagement = Loadable({
    loader: () => import('./Container/Auth/Reseller/ResellerManagement'),
    delay: 60, loading: Loading
});
const ResellerCreateManager = Loadable({
    loader: () => import('./Container/Auth/Reseller/ResellerCreateManager'),
    delay: 60, loading: Loading
});

/*================================================Product Module ==========================================================================*/
const ProductListManager = Loadable({
    loader: () => import('./Container/Auth/Product/ProductListManager'),
    delay: 60, loading: Loading
});
const ProductRateManager = Loadable({
    loader: () => import('./Container/Auth/Product/ProductRateManager'),
    delay: 60, loading: Loading
});

const ProductCreateManager = Loadable({
    loader: () => import('./Container/Auth/Product/ProductCreateManager'),
    delay: 60, loading: Loading
});

const PackageManager = Loadable({
    loader: () => import('./Container/Auth/Product/PackageManager'),
    delay: 60, loading: Loading
});



/*================================================Recharge & Support Module ==========================================================================*/
const SupportManagerAuth = Loadable({
    loader: () => import('./Container/Auth/SupportManagerAuth'),
    delay: 60, loading: Loading
});

const RechargeInquiryManagerAuth = Loadable({
    loader: () => import('./Container/Auth/RechargeInquiryManagerAuth'),
    delay: 60, loading: Loading
});


// ============================= Change Mobile Number ===============================
const ChangeMobileNumber = Loadable({
    loader: () => import('./Container/Auth/ChangeMobileNumber/ChangeMobileNumber'),
    delay: 60, loading: Loading
});
// const ChangePhoneNumber = Loadable({
//     loader: () => import('./Component/NotAuth/ChangePhoneNumber'),
//     delay:60,loading: Loading
// });


class App extends Component {


    constructor(props) {
        super(props);
        this.state = {
            render: true,
            hasError: false
        }
        //this.checkDeviceRegistration();
        //window.onload = function () {
            // if (getSession('sessionData')) {
            //     console.log("getSession called");
            //      let userDevice = getSession('sessionData').userDevice;
            //      if (userDevice && userDevice.isAvailable > -1) {
            //          console.log("remove sessionData");
            //          localStorage.removeItem('sessionData');
            //      }
            // };
        // }
    }



    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true
        })
    }

    componentDidMount() {
        setTimeout(this.getToken(), 500);
    }
    
    getDaysBetweenDates = (d0, d1) => {

        let msPerDay = 8.64e7;
        let x0 = new Date(d0);
        let x1 = new Date(d1);
        x0.setHours(12, 0, 0);
        x1.setHours(12, 0, 0);
        return Math.round((x1 - x0) / msPerDay);
    };
    getData = (d) => {
        return d.getFullYear() + '/' + d.getMonth() + '/' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes();
    };
    getToken() {
        axiosAuth.post(apiRoute.token, {
            "clientId": "sohoj-app",
            "clientSecret": "wOA%Z%'C)>fs1},",
            "grantType": "client_credentials",
            "nonce": Math.floor(Math.random() * 10000).toString()
        }).then(res => {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    let prevDate = localStorage.getItem('ss_t');
                    let curDate = this.getData(new Date());
                    localStorage.setItem('authToken', res.data.data[0].access_token);
                    localStorage.setItem('token_type', res.data.data[0].token_type);

                    if (this.getDaysBetweenDates(prevDate, curDate) > 0) {
                        localStorage.removeItem('activeSubMenu');
                        localStorage.removeItem('activeMenu');
                        localStorage.removeItem('sessionData');
                        localStorage.removeItem('role');
                        localStorage.removeItem('currency');
                        localStorage.removeItem('activeCurrency');
                        localStorage.removeItem('notification');
                        localStorage.setItem('ss_t', curDate);
                    } else {
                        localStorage.setItem('ss_t', curDate);
                    }




                    this.setState({ 'render': true });
                }
            }
        })
    }

    render() {
        if (this.state.hasError) {
            return (
                <h3 style={{ color: 'red', padding: '20px' }}>Something unexpected happened. In Case of Emergency Please <Link to={"/supports"}>click here & contact</Link></h3>
            )
        } else if (this.state.render) {
            return (
                <Switch>

                    {/*Common Route*/}
                    <Route path="/privacy-policy" exact component={PrivacyPolicyManager} />
                    <Route path="/terms-conditions" exact component={TermsConditionManager} />
                    <Route path="/supports" exact component={SupportManager} />
                    <Route path="/sign-out" exact component={SignOutManager} />
                    <Route path="/support" exact component={SupportManagerAuth} />
                    {/*Not Auth Route*/}
                    <PrivateRouteNotAuth path="/" exact component={RechargeInquiryManager} />
                    <PrivateRouteNotAuth path="/sign-in" exact component={SignInManger} />
                    <PrivateRouteNotAuth path="/sign-up" exact component={SignUpManager} />
                    <PrivateRouteNotAuth path="/otp" exact component={OtpVerificationManager} />
                    <PrivateRouteNotAuth path="/message" exact component={MessageManager} />
                    <PrivateRouteNotAuth path="/forget-password" exact component={ForgetPasswordManager} />
                    <PrivateRouteNotAuth path="/change-password" exact component={ChangePasswordManager} />

                    {/*Dashboard Route*/}
                    <PrivateRouteAuth path="/dashboard" exact component={DashboardManager} />
                    <PrivateRouteAuth path="/admin-dashboard" exact component={AdminDashboard} />

                    {/*Serviec Route*/}
                    <PrivateRouteAuth path="/topup" exact component={TopUpManager} />
                    <PrivateRouteAuth path="/internet" exact component={InternetBalanceManager} />
                    <PrivateRouteAuth path="/sohoj-recharge" exact component={SohojRechargeManager} />
                    <PrivateRouteAuth path="/fund-transfer" exact component={FundTransferManager} />
                    <PrivateRouteAuth path="/bulk-flexiload" exact component={BulkUploadManager} />
                    <PrivateRouteAuth path="/pin-generate" exact component={PinGenerateManager} />
                    <PrivateRouteAuth path="/cash-recharge" exact component={CashRechargeManager} />
                    <PrivateRouteAuth path="/purchase-balance" exact component={PurchaseBalanceManager} />
                    <PrivateRouteAuth path="/bill-payment" exact component={BillPaymentManager} />

                    {/*Report Route*/}
                    <PrivateRouteAuth path="/report" exact component={MyReportManager} />
                    <PrivateRouteAuth path="/sales-report" exact component={SalesReportManager} />
                    <PrivateRouteAuth path="/deposit-report" exact component={DepositReportManager} />
                    <PrivateRouteAuth path="/execution-report" exact component={ExecutionReportManager} />
                    <PrivateRouteAuth path="/purchase-request" exact component={salesFundTransferRequests} />
                    <PrivateRouteAuth path="/card-report" exact component={CardReportManager} />

                    {/*Profile Route*/}
                    <PrivateRouteAuth path="/settings-change-password" exact component={ProfileChangePassword} />
                    <PrivateRouteAuth path="/settings-notification" exact component={ProfileChangeNotification} />
                    <PrivateRouteAuth path="/settings-profile-update" exact component={ProfileUpdatePic} />

                    {/*User Route*/}
                    <PrivateRouteAuth path="/users-view" exact component={UserManagement} />
                    <PrivateRouteAuth path="/users-create" exact component={UserCreateManager} />
                    <PrivateRouteAuth path="/role" exact component={RoleManager} />
                    <PrivateRouteAuth path="/role-settings" exact component={RoleSettingsManager} />
                    <PrivateRouteAuth path="/sales-route" exact component={SalesRouteManager} />
                    <PrivateRouteAuth path="/cash-agent" exact component={CashRechargeAgent} />

                    {/*Sales Route*/}
                    <PrivateRouteAuth path="/sales-card-invoice" exact component={salesCardInvoice} />
                    <PrivateRouteAuth path="/sales-deposits" exact component={salesDeposits} />
                    <PrivateRouteAuth path="/card-transfer" exact component={CardTransferManager} />

                    {/*Lot Card Route*/}
                    <PrivateRouteAuth path="/card-generate" exact component={CardGenerateManager} />
                    <PrivateRouteAuth path="/lot-generate" exact component={LotGenerateManager} />
                    <PrivateRouteAuth path="/product-activation" exact component={LotActivationManager} />
                    <PrivateRouteAuth path="/lot-product-relation" exact component={LotProductRelationManager} />

                    {/*Reseller Route*/}
                    <PrivateRouteAuth path="/reseller-view" exact component={ResellerManagement} />
                    <PrivateRouteAuth path="/reseller-create" exact component={ResellerCreateManager} />

                    {/*Configuration Route*/}
                    <PrivateRouteAuth path="/user-commission" exact component={UserCommissionManagement} />
                    <PrivateRouteAuth path="/user-currency" exact component={UserCurrency} />
                    <PrivateRouteAuth path="/system-configuration" exact component={UserConfiguration} />
                    <PrivateRouteAuth path="/add-balance" exact component={AddBalanceManager} />

                    {/*Product Route*/}
                    <PrivateRouteAuth path="/product" exact component={ProductListManager} />
                    <PrivateRouteAuth path="/product-rate" exact component={ProductRateManager} />
                    <PrivateRouteAuth path="/product-create" exact component={ProductCreateManager} />
                    <PrivateRouteAuth path="/package" exact component={PackageManager} />

                    {/*Recharge & Support Route*/}
                    <PrivateRouteAuth path="/recharge-inquiry" exact component={RechargeInquiryManagerAuth} />

                    {/* User Device Registration and Deletion */}
                    <PrivateRouteNotAuth path="/device-registration" exact component={UserDeviceRegistrationManager} />


                    {/* Change Phone Number Route */}
                    <Route path="/change-phone-number" exact component={ChangeMobileNumber} />

                    {/* Show and Remove Device List */}
                    <Route path="/my-devices" exact component={UserDeviceListMenu} />

                    {/* Get Invoice Info Route */}
                    <Route path="/get-invoice" exact component={Invoice} />

                    <Route path="/sign-out" exact component={SignOutManager} />

                </Switch>
            );
        } else {
            return (
                <Auxi>
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-md-8">
                            <div className="loader" style={{ top: '100%' }} />
                            <h4 style={{ "textAlign": "center" }}>this may take a few seconds....</h4>
                        </div>
                    </div>
                </Auxi>
            )
        }

    }
}


export default App;
