import axios from 'axios';
import jose from 'node-jose';
import apiRoute from './apiRoute';
import salts from './fetchSalts';
require('dotenv');

let defaultAauth = {

    baseURL : apiRoute.baseUrl,
};




function encrypt(data){

    return new Promise((resolve, reject)=>{
        jose.JWK.asKeyStore(salts).then(result => {
            jose.JWE.createEncrypt({ format: 'compact', zip: true }, result.get(salts.keys[0].kid))
                .update(JSON.stringify(data))
                .final()
                .then(res => {
                    return resolve(res);
                }).catch((err) => {
                return reject(err);
            });
        });
    })
}

function decrypt(data){
    return new Promise((resolve, reject)=>{
        jose.JWK.asKeyStore(salts).then(result => {
            jose.JWE.createDecrypt(result.get(salts.keys[0].kid))
                .decrypt(data)
                .then(res => {
                    let parsed = JSON.parse(res.payload.toString());
                    return resolve(parsed);
                }).catch((err) => {
                return reject(err);
            });
        });
    })
}



const instance = axios.create(defaultAauth);

instance.interceptors.request.use( async req => {
        let data = '';
        data = await encrypt(req.data);
        req.data = { payload :data };
        return req;
    }, err => {
        return Promise.reject(err);
    }
);




instance.interceptors.response.use( async res => {


    if(res.data.data === undefined){
        res.data.data = [];
        return res;
    }else{
        let data = '';
        data = await decrypt(res.data.data);
        res.data.data = data;
        return res;
    }
}, err => {
    return Promise.reject(err);
});

export default instance;