import React, { useEffect,lazy, useState }  from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from '../../../axios';
import apiRoute from '../../../apiRoute';
import Receipt from './Receipt';
import Auxi from '../../../hoc/Auxi';
import Layout from '../../../Component/Layout/NotAuth';
import getSession from '../../../Component/UI/Session/getSession';




export default function Invoice(props) {
   
    const { search } = useLocation();
    const history  = useHistory();
    const [resultData, setResultData] = useState(null);
    const [transactionId, setTransactionId] = useState('');
    const [error, setError] = useState(false);

    let queryStr = search.substr(1);


    useEffect(() => {
        let strArray = [];
        let tokenType;
        let authToken;
        let authorization;
        
        if(queryStr){
            let bytes  = CryptoJS.AES.decrypt(queryStr.toString(), 'secret key 123---987--000');
            let plaintext = bytes.toString(CryptoJS.enc.Utf8);
            console.log("decrypted text", plaintext);
    
            strArray = plaintext.split(',');
            console.log("userId is  ", strArray[0]);
            console.log("sessionId is ", strArray[1]);
            console.log("transferId is ", strArray[2]);
            setTransactionId(strArray[2]);
        }else{
            console.log("uid sid tid is ", props.userId, props.sessionId, props.transactionId)
                strArray[0] = props.userId;
                strArray[1] = props.sessionId;
                strArray[2] = props.transactionId;               
        }

        tokenType = getSession('token_type');
        authToken = getSession('authToken');
        authorization = { Authorization: ` ${tokenType} ${authToken}` };
        

        let axiosData = {
            userId: strArray[0],
            sessionId: strArray[1],
            transferId: strArray[2]
            // type: 2 , //balance receive history type 3 will be placed here
        };

        console.log("axios data is ", axiosData);
        console.log("authorization is ", authorization);

            //axios.post('http://45.249.100.23:3001/api/transaction/reports/generate', axiosData, {headers: authorization})
            axios.post(apiRoute.invoice.invoiceFormatData, axiosData, {headers: authorization})           
            .then(res => {
                console.log("response from api is", res);
                if(res.status === 200){
                    if(res.data.status === 200){
                        let reportData = res.data.data[0];
                        console.log("report data is ", reportData);
                        setResultData(reportData);
                    }else{
                        console.log("response err", res.data); 
                        setError(true);         
                    }
                }
                else{
                    console.log("err is ", res.data);
                    setError(true);
                }
            })
            .catch((error) => {
                console.log("ERROR " + error);
            })
        

    }, [])

    if(error){
        return (
            <div className="help-block">
                <h2 className="text-center text-danger font-weight-bold">Something went wrong</h2>
            </div>
        )
    }
    else if(!resultData){
        return(
            <Auxi>
                {/* <Header> */}
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-md-8">
                            <div className="loader" style={{top: '0%'}}></div>
                            <h4 style={{"textAlign": "center"}}>this may take a few seconds....</h4>
                        </div>
                    </div>
                {/* </Header> */}
            </Auxi>
        )
    }else{
        return (
            // <Layout>
                <Receipt 
                    reportData={resultData}
                    transactionId={transactionId}
                    error={error}
                />
            // </Layout>
        )   
    }
    
        
}
