import React from 'react';
import Auxi from '../../hoc/Auxi';
import Header from './Header';
import Footer from './Footer';
import lifecycle from 'react-pure-lifecycle';

const method = {
    componentDidMount(){
        document.body.className= "header-fixed sign-in";
    }
};
const NotAuth = (props)=>{
    return (
        <Auxi>
            <Header {...props}/>
            <div className="app-body sign-in">
                <div className="content-wrapper-notAuth mt-5">
                    {props.children}
                <Footer/>
                </div>
            </div>    
        </Auxi>
    )
};

export default lifecycle(method)(NotAuth);