
import Axios from 'axios';
import React, { useState }  from 'react'
import { Redirect } from 'react-router';
import { NavLink, useHistory } from 'react-router-dom'
import getSession from '../../../Component/UI/Session/getSession';
import axios from '../../../axios';
import apiRoute from '../../../apiRoute';
import Auxi from '../../../hoc/Auxi';
import Header from '../../../Component/Layout/Auth'
// import OtpVerification from '../../Component/NotAuth/OTP/OtpVerification';
import destroySession from '../../../Component/UI/Session/destroySession'
import UserDeviceRegistrationManager from '../../../Container/NotAuth/UserDeviceRegistrationManager';


export default function UserDeviceDeleteCheckPass(props) {
    const [password, setPassword] = useState('');
    const [submit, isSubmit] = useState(false);
    const [error, hasError] = useState(false);
    const [deviceDeleteError, hasDeviceDeleteError] =useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sucessfull, isSuccessfull] = useState(false);
    const [apiResponse, isApiResponse] = useState(true); 
    const [redirect, setRedirect] = useState(false);
    const history = useHistory();

    let errorMessage;
    let errorTypeMessage;
    let deviceDeleteErrorMessage='';
    let axiosData = {};

    const handleChange = (e) => {
        console.log("event is", e.target.value);
        const {value} = e.target;
        setPassword(value);
    };
    const handleSubmit = (e) => {
        e.preventDefault();  
        spinnerHandler();
        isSubmit(true);
    };

    const spinnerHandler = ()=>{
        console.log("isLoading is called or not", isLoading);
        setIsLoading(!isLoading);
    };


    if(submit){
        console.log("api method is called");
      //  spinnerHandler();
        isSubmit(false);

        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };

        axiosData = {
            userId: props.userId,
            sessionId: props.sessionId,
            password: password
        };

        console.log("userId is ", props.userId);

        axios.post(apiRoute.checkPassword,axiosData,{headers: authorization}).then(res => {
            if(res.status === 200){
                if(res.data.status === 200){
                    console.log("successful response from api");
                    axiosData = {
                        userId: props.userId,
                        sessionId: props.sessionId,
                        deviceId: props.deviceId,
                    };
                    axios.post(apiRoute.deleteDevice,axiosData,{headers: authorization}).then(res => {
                        if(res.status === 200){
                            if(res.data.status === 200){
                                console.log("successfully device deleted");
                                spinnerHandler();
                                hasError(false);
                                hasDeviceDeleteError(false);
                                isSuccessfull(true);  
                                isApiResponse(true);
                            }
                            else{
                                spinnerHandler();
                                hasError(false);
                                hasDeviceDeleteError(true);
                                isApiResponse(true);
                                console.log("failure reponse from device delete api", res.data);          
                            }
                        }
                        else{
                            spinnerHandler();
                            isApiResponse(false);
                            console.log("something went wrong in time of device delete");           
                        }
                    })

                }else{
                    spinnerHandler();
                    hasError(true);
                    isApiResponse(true);
                    console.log("failure reponse from api");
                    
                }
            }
            else{
                console.log("something went wrong in time of sending api");
                spinnerHandler();
                isApiResponse(false);
            }
        })
        
    }

    errorMessage = error ? <span className="help-block">
    <strong className="text-danger font-weight-bold">Password does not match</strong>
    </span> : null;

    errorTypeMessage = !apiResponse ? <span className="help-block">
    <strong className="text-danger font-weight-bold">Something went wrong please try again</strong>
    </span> : null;

    deviceDeleteErrorMessage= deviceDeleteError ? <span className="help-block">
    <strong className="text-danger font-weight-bold">Device deletion failed</strong>
    </span> : null;

    if(sucessfull){
        if(props.fromDeviceListMenu){
            if(props.deviceId != 'all'){
                console.log("menu device list is called");
                return <Redirect to={ '/dashboard' }/>
            }else{
                console.log("delete all triggered so logout");
                // const { from } =   { from : {pathname: '/sign-out', state:{}}};
                if(destroySession){
                    return <Redirect to={'/sign-in'}/>
                    // return (history.push(from))
                }
            }         
        }else{
            console.log("successfully render register after device deletion", props);
            return (
                <UserDeviceRegistrationManager
                    {...props}
                    deletedDeviceId={props.deviceId}
                />
            )
        }
    
    }

    else if(isLoading){
            return(
                <Auxi>
                    {/* <Header> */}
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-md-8">
                                <div className="loader" style={{top: '0%'}}></div>
                                <h4 style={{"textAlign": "center"}}>this may take a few seconds....</h4>
                            </div>
                        </div>
                    {/* </Header> */}
                </Auxi>
            );
    }
    
    else {
        return (
        <div className="container">
        <div className="row justify-content-center">
            <div className="col-xl-6 col-md-8">
                <div className="card">
                    <div className="card-body p-lg-5">
                        <h2 className="weight600 mt-3- mb-4 text-center">{props.fromDeviceListMenu ? `Registered Devices` : `Device Registration`}</h2>
                        <h3 className="weight500">Enter your password</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                {/* <label>Phone number</label> */}
                                <div className="input-group-field">
                                    {/* <i className="si-pin"/> */}
                                    <input autoFocus required={true} type="password" value={password} onChange={(e)=>{handleChange(e)}} className="form-control" placeholder="Password"/>
                                    {/* <a href="javascript:;" onClick={() => {props.showHidePassword()}} className={`${props.slashClass} show-pwd-btn`}/> */}
                                        {errorMessage}
                                        {errorTypeMessage}
                                        {deviceDeleteErrorMessage}
                                </div>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary btn-block">CONFIRM</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

}
