const getSession = (key) => {
    if(key === 'role' || key === 'authToken' || key === 'token_type'){
        return localStorage.getItem(key);
    }else{
        try{
            return JSON.parse(localStorage.getItem(key));

        }catch (e){
            return 0;
        }
    }
};

export default getSession;
