import React from 'react';
import sessionData from "../UI/Session/getSession";



export default ()=>{
    let notification = '';
    if((sessionData('notification')) === null){
        notification = ''
    }else{
        notification = sessionData('notification').configValue;
    }
    if(notification !== ''){
        return(
            <footer className="floating-footer">

                <div className="text-center">
                    <div className="notification">
                        <h3>{notification} </h3>
                    </div>
                </div>
            </footer>
        )
    } else{
        return(
            <footer className="sticky-footer">
                <div className="text-center">
                    &copy; Sohoj 2019
                </div>
            </footer>
        )
    }

}

