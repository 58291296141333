import React from 'react';
import Avatar from '../../assets/avatar.png';
import apiRoute from '../../apiRoute';
import getSession from '../UI/Session/getSession';

const ProfilePicture = (props) =>{
    const ProfilePic = getSession('sessionData');
    let pic = '';
    if(ProfilePic !== []){
        pic = ProfilePic.profilePicture
    }else{
        pic = null;
    }
    return <img
        src={ pic !== null ? apiRoute.imageUrl+pic: Avatar}
        className="rounded-circle"
        style={{"width": "50px", "height": "50px","objectFit": "cover","borderRadius": "50%"}}
        alt="User Image"/>
};



export default ProfilePicture;