import getSession from './getSession';
import axios from '../../../axios';
import axiosNotAuth from '../../../axiosNonAuth';
import apiRoute from '../../../apiRoute';

const createSession = (props) => {

    console.log(" createSession Props: ", props);

    const axiosAccount = {
        "accountName": apiRoute.accountName,
        "accountPassword": apiRoute.accountPassword
    };


    localStorage.setItem('sessionData', JSON.stringify(props));
    localStorage.setItem('role', (props.menuPermission));
    localStorage.setItem('activeMenu', JSON.stringify(0));
    let tokenType = getSession('token_type');
    let authToken = getSession('authToken');
    let authorization = { Authorization: ` ${tokenType} ${authToken}` };

    axios.post(apiRoute.currency.currencyList, axiosAccount,{headers: authorization})
        .then(res=>{
            if(res.status === 200){
                if(res.data.status === 200){
                    localStorage.setItem('currency', JSON.stringify(res.data.data));
                    res.data.data.map( (val, key)=>{
                        if(val.isBaseCurrency){
                            localStorage.setItem('activeCurrency', val.currencyId);
                        }
                        return 0;
                    })
                }
            }
        });

    let userID = getSession('sessionData').userId;
    let sessionID = getSession('sessionData').sessionId;

    const axiosData = {

        "userId": userID,
        "sessionId": sessionID,
        "configType": 'Notification'

    };

    axiosNotAuth.post(apiRoute.configuration.show, axiosData,{headers: authorization})
        .then(res=>{
            if(res.status === 200){
                if(res.data.status === 200){
                    localStorage.setItem('notification', JSON.stringify(res.data.data[0]));
                    res.data.data.map( (val, key)=>{
                        if(val.isBaseCurrency){
                            localStorage.setItem('activeCurrency', val.currencyId);
                        }
                        return 0;
                    })
                }
            }
        });
 if(getSession('sessionData')){
     return true;
 }else{
     return false;
 }
};

export default createSession;
