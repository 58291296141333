import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import getSession from "../UI/Session/getSession";
import ProfilePic from "./ProfilePic";
import NumberFormat from "react-number-format";
import axios from "../../axios";
import apiRoute from "../../apiRoute";
import modifySession from "../UI/Session/modifySession";
import { Redirect } from "react-router";
import Auxi from "../../hoc/Auxi";

export default (props) => {
  try {
    let session = getSession("sessionData");
    let username = session.username;
    let userID = session.userId;
    let userCurrencyName = session.currencyName;
    const [userBalance, setUserBalance] = useState(session.userBalance);
    const [
      isRequiredBusinessAgreementTime,
      setIsRequiredBusinessAgreementTime,
    ] = useState(false);
    const [isRequiredBusinessAgreement, setIsRequiredBusinessAgreement] =
      useState();
    useEffect(() => {
      setUserBalance(session.userBalance);
    }, [session.userBalance]);
    const checkBalance = () => {
      let session = getSession("sessionData");
      let sessionID = session.sessionId;
      let userID = session.userId;

      let axiosData = {
        userId: userID,
        sessionId: sessionID,
      };
      let tokenType = getSession("token_type");
      let authToken = getSession("authToken");
      let authorization = { Authorization: ` ${tokenType} ${authToken}` };
      axios
        .post(apiRoute.user.getUserInfo, axiosData, { headers: authorization })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 200) {
              modifySession(res.data.data[0].userBalance);
              setUserBalance(res.data.data[0].userBalance);
              setIsRequiredBusinessAgreement(
                res.data.data[0].isRequiredBusinessAgreement
              );

              setTimeout(() => {
                setIsRequiredBusinessAgreementTime(true);
              }, 3000);
            }
          }
        });
    };
    if (isRequiredBusinessAgreement === 1) {
      if (isRequiredBusinessAgreementTime) return <Redirect to={"/sign-out"} />;

      return (
        <Auxi>
          <div className="row justify-content-center" style={{ marginTop: 90 }}>
            <div className="col-xl-8 col-md-10">
              <div className="loader" style={{ top: "0%;" }}></div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    '<p id="firstSecond"><style>.container-fluid{display:none;}</style></p>',
                }}
              ></div>
              <h3
                style={{
                  color: "red",
                  padding: "0px",
                  marginTop: 0,
                  textAlign: "center",
                }}
              >
                Please read and accept business policy agreement of Sohoj along
                with providing your Photo ID and e-sign in “Sohoj Agent App".
              </h3>
              <h3
                style={{
                  color: "red",
                  padding: "0px",
                  marginTop: 0,
                  textAlign: "center",
                }}
              >
                System will automatically log out after few seconds;
              </h3>
            </div>
          </div>
        </Auxi>
      );
    }
    return (
      <li className="nav-item dropdown ">
        <a
          className="nav-link dropdown-toggle"
          id="userNav"
          href="javascript:void(0)"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="user-thumb">
            <ProfilePic />
          </div>
        </a>
        <div
          className="dropdown-menu dropdown-menu-right profile-info"
          aria-labelledby="userNav"
        >
          <div className="profile-id mt-5">
            <ProfilePic />
            <h5>UserID: {userID}</h5>
            <h5 className="mb-0-">Name: {username}</h5>

            <NumberFormat
              value={userBalance}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              renderText={(value) => (
                <p>
                  Balance: {value}
                </p>
              )}
            />
            <a href="javascript:void(0)" onClick={(e) => checkBalance()}>
              <i className="fa fa-refresh" />
            </a>
          </div>
          <NavLink to={"/sign-out"} className="dropdown-item" href="#">
            <i className="si-user_border mr-2" />
            Logout
          </NavLink>
        </div>
      </li>
    );
  } catch (e) {
    return "";
  }
};
