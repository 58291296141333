import Axios from 'axios';
import React, { useState, useEffect }  from 'react'
import { Redirect } from 'react-router';
import { NavLink, useHistory,Route } from 'react-router-dom'
import getSession from '../../../Component/UI/Session/getSession';
import axios from '../../../axios';
import apiRoute from '../../../apiRoute';
import Auxi from '../../../hoc/Auxi';
import Header from '../../../Component/Layout/Auth'
// import OtpVerification from '../../Component/NotAuth/OTP/OtpVerification';
import destroySession from '../../../Component/UI/Session/destroySession'
import UserDeviceRegistrationManager from '../../../Container/NotAuth/UserDeviceRegistrationManager';


export default function UserDeviceListMenu() {
    const [error, hasError] = useState(false);
    const [apiResponse, isApiResponse] = useState(true); 
    const [isLoading, setIsLoading] = useState(false);
    const [devices, setDevices] = useState([]);
    const history = useHistory();
    
    const spinnerHandler = ()=>{
        console.log("isLoading is called or not", isLoading);
        setIsLoading(!isLoading);
    };

    useEffect(() => {
        console.log("hit useEffect after delete");
    
        // spinnerHandler();
        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };
        
        
        let userId = getSession('sessionData') ? getSession('sessionData').userId : null;
        let sessionId = getSession('sessionData') ? getSession('sessionData').sessionId : null;

        let axiosData = {
            userId,
            sessionId
        };

        axios.post(apiRoute.deviceList,axiosData,{headers: authorization}).then(res => {
            console.log("response from api is", res.status);
            if(res.status === 200){
                if(res.data.status === 200){
                    console.log("device list is", res.data.data);
                    setDevices(res.data.data);
                    // spinnerHandler();
                    hasError(false);
                    isApiResponse(true);
                }else{
                    spinnerHandler();
                    hasError(true);
                    isApiResponse(true);
                    console.log("response data from send otp", res.data);
                    console.log("failure reponse from api");                 
                }
            }
            else{
                console.log("something went wrong in time of sending api");
                spinnerHandler();
                isApiResponse(false);
            }
        })
        
    }, [])

    if(!getSession('sessionData')){
        return(
            <Route render={({ history}) => ( history.push('/') 
                )} />
            );
    }
    if(isLoading){
        return(
            <Auxi>
                {/* <Header> */}
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-md-8">
                            <div className="loader" style={{top: '0%'}}></div>
                            <h4 style={{"textAlign": "center"}}>this may take a few seconds....</h4>
                        </div>
                    </div>
                {/* </Header> */}
            </Auxi>
        );
    }
    else{
        console.log("hit device list menu");
        let userId = getSession('sessionData') ? getSession('sessionData').userId : null;
        let sessionId = getSession('sessionData') ? getSession('sessionData').sessionId : null;
        const userDeviceInfo ={
            devices 
        };     
        return (
            
            // <Auxi>
            //     <Header>
                    <UserDeviceRegistrationManager
                        userDeviceInfo={userDeviceInfo}
                        userId={userId}
                        sessionId={sessionId}
                        fromDeviceListMenu={true}
                    />
            //     </Header>
                
            // </Auxi>
        )
    }

    
}
