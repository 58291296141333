import sessionData from '../Component/UI/Session/getSession';

/*const Auth = ()=> {
    return sessionData('sessionData')
        ? true
        : false;

};
export default Auth;*/




const Auth = (path)=> {

    if(sessionData('sessionData') && localStorage.getItem('isUserDeviceInfoVarified') === 'true'){
        if(sessionData('role')) {
            let data = JSON.parse(sessionData('role'));
            let checker = 'fake';
            data.map((sd, si) => {
                if (sd.sub.length > 0) {
                    sd.sub.map((sds, sdi) => {
                        if (path === '/' + sds.path && sds.permission[0] === 'on') {
                            checker = true;
                        }
                        return 0
                    })
                } else {
                    if (path === '/' + sd.path && sd.permission[0] === 'on') {
                        checker = true;
                    }
                }
                return 0;
            });
            return checker;
        }else{
            return false;
        }
    }else{
        return false;
    }



/*

     if(sessionData('sessionData')){
        if(sessionData('role')) {
            let menu = sessionData('activeSubMenu');
            let role = JSON.parse(sessionData('role'));
            let status = false;
            if (role[sessionData('activeMenu')].sub.length > 0) {
                role[sessionData('activeMenu')].sub.map((d, i) => {
                    if (parseInt(menu,10) === parseInt(d.key,10) && status !== true && d.permission[0] === 'on' && '/'+d.path === path) {
                        status = true;
                    }else{
                       if(!status)
                        status = 'fake';
                    }
                    return 0;
                });
            } else {
                if (role[sessionData('activeMenu')].permission[0] === 'on' && '/'+role[sessionData('activeMenu')].path === path) {
                   status = true;
                }else{
                    status = 'fake';
                }
            }

            return  status;
        }else{
           return true;
        }

     }else{
        return false;
     }*/

};

export default Auth;
