import React from 'react';
import {NavLink} from 'react-router-dom';
const logo = require('../../assets/logo.svg');
const avatar = require('../../assets/avatar.png');
export default (props)=>{
    return(
        <header className="app-header text-center">

            {props.redirectUrl ? <ul className="nav float-left pl-0">
                    <li className="nav-item">
                        <NavLink to={props.redirectUrl} className="nav-link mt-lg-3 mt-2 text-dark">
                            <div className="user-thumb">
                                <i className="si-arrow-left"/>
                                <span className="ml-2">Back</span>
                            </div>
                        </NavLink>
                    </li>
                </ul>
                :  '' }


            <div className="branding-wrap">
                <div className="navbar-brand">
                    <NavLink className="" to="/">
                        <img src={logo} alt="sohoj" />
                    </NavLink>
                </div>
            </div>

            {props.redirectUrl ?
                <ul className="header-links hide-arrow navbar">
                    <li className="nav-item dropdown ">
                        <NavLink to={props.redirectUrl} className="nav-link dropdown-toggle pt-lg-0" id="userNav"  aria-expanded="false">
                            <div className="user-thumb">
                                <img src={avatar} alt="sohoj" />
                                <span className="ml-2">{props.redirectName}</span>
                            </div>
                        </NavLink>
                    </li>
                </ul>
                : '' }


        </header>
    );
}