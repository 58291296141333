import React from 'react';
import {NavLink} from 'react-router-dom';
import getSession from '../Session/getSession';


const Aside =()=>{

    try{
       const  menuHandler = (v, i)=>{
            localStorage.setItem(i, v);
        };

        const roleData = (JSON.parse(getSession('role')));
        let menuData = {
            "icon": "si-phone",
            "path": "change-phone-number",
            "name": "Change Phone Number",
            "key": "13",
            "permissionName": ["View"],
            "permission": ["on"],
            "sub": []
        };
        // roleData.push(menuData);
        let newMenuData = {
            "icon": "fa fa-mobile",
            "path": "my-devices",
            "name": " My Devices",
            "key": "14",
            "permissionName": ["View"],
            "permission": ["on"],
            "sub": []
        };
        roleData.push(menuData, newMenuData);
        const activeMenu = getSession('activeMenu');
        const activeSubMenu = getSession('activeSubMenu');
        return (
            <div className="left-nav-wrap">
                <div className="left-sidebar">
                    <nav className="sidebar-menu">
                        <ul id="nav-accordion">

                            { roleData.map((permissions, index) => {
                                if(permissions.sub.length === 0){
                                    if(permissions.permission[0] === 'on')
                                        return  <li className={ (activeMenu === index) ? 'active' : ''} key={index} onClick={ (e)=>{ menuHandler(index, 'activeMenu')}}>
                                            <NavLink to={{pathname: permissions.path, flushState: true}} >
                                                <i className={permissions.icon}/>
                                                <span>
                                                    {permissions.name}
                                                </span>
                                            </NavLink>
                                        </li>
                                }else{
                                    if(permissions.permission[0] === 'on')
                                        return (<li className="sub-menu"
                                                    style={{'display': 'block'}} key={index}>
                                            <a href="javascript:void(0)" className={ (activeMenu === index) ? 'active' : ''}>
                                                <i className={permissions.icon}/>
                                                {permissions.name}
                                            </a>
                                            <ul className="sub">
                                                {permissions.sub.map((subMenu, indexSub) => {
                                                        if(subMenu.permission[0] === 'on') {
                                                            return <li className={( parseInt(activeSubMenu) === parseInt(subMenu.key)) ? 'active' : ''} key={indexSub}
                                                                       onClick={(e) => {
                                                                           menuHandler(subMenu.key, 'activeSubMenu');
                                                                           menuHandler(index, 'activeMenu');
                                                                       }}>
                                                                <NavLink to={{pathname: subMenu.path, flushState: true}}
                                                                         className={( parseInt(activeSubMenu) === parseInt(subMenu.key)) ? 'active' : ''}>
                                                                    {subMenu.name}
                                                                </NavLink>
                                                            </li>
                                                        }
                                                        return '';
                                                    }
                                                )}
                                            </ul>
                                        </li>)
                                }
                                return '';
                            })
                            }
                        </ul>
                    </nav>
                </div>
            </div>

        );
    }catch(e){
        return ('');
    }



}

export default Aside;

