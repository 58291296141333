import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from './Auth';
import destroySession from '../Component/UI/Session/destroySession';
/*import {createBrowserHistory} from 'history';


const history = createBrowserHistory({
    basename: "", // The base URL of the app (see below)
    forceRefresh: true, // Set true to force full page refreshes
    keyLength: 6, // The length of location.key
    // A function to use to confirm navigation with the user (see below)
    //getUserConfirmation: (message, callback) => callback(window.confirm(message))
});*/


const PrivateRouteAuth = ({component: Component, ...rest}) => {
   // console.log(Auth(rest.path));
    let checker = Auth(rest.path);
    if(checker === false){
        destroySession();
    }


    // option to redirect with router  force redirect
/*    return (
        <Router history={history}>
            <Route {...rest} render={(props) => (
                checker === true
                    ? <Component {...props} />
                    : checker === 'fake' ? <Redirect to={{
                        pathname: '/dashboard',
                        state: {from: props.location}
                    }}/> : <Redirect to={{
                        pathname: '/signin',
                        state: {from: props.location}
                    }}/>
            )}/>
        </Router>
    )*/

    // option to redirect with router redirect
   return (
        <Route {...rest} render={(props) => (
            checker === true
                ? <Component {...props} />
                : checker === 'fake' ? <Redirect to={{
                    pathname: '/dashboard',
                    state: {from: props.location}
                }}/> : <Redirect to={{
                    pathname: '/sign-in',
                    state: {from: props.location}
                }}/>
        )}/>
    )



    // option to redirect with windol.location.href
    /*return (
        <Route {...rest} render={(props) => (
            checker === true
                ? <Component {...props} />
                : checker === 'fake' ?
                window.location.href = `/dashboard` :
                window.location.href = `/signin`
        )}/>
    )*/



};

export default PrivateRouteAuth;