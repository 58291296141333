import getSession from './getSession';
const modifySession = (data) => {

    const sessionData = getSession('sessionData');
    if(sessionData){
        sessionData.userBalance = data;
        localStorage.setItem('sessionData',JSON.stringify(sessionData) );
        return true;
    }else{
        return false;
    }
};


export default modifySession;
