import axios from 'axios';
import apiRoute from './apiRoute';
require('dotenv');

const instance = axios.create({
    baseURL : apiRoute.baseUrl,
});


instance.interceptors.request.use( req => {
        return req;
    }, err => {
        return Promise.reject(err);
    }
);


instance.interceptors.response.use( res => {
    return res;
}, err => {
    return Promise.reject(err);
});

export default instance;