import React from 'react';
import { Route, Redirect } from 'react-router';
import NoAuth from './NoAuth';

/*import {createBrowserHistory} from 'history';

const history = createBrowserHistory({
    basename: "", // The base URL of the app (see below)
    forceRefresh: true, // Set true to force full page refreshes
    keyLength: 6, // The length of location.key
    // A function to use to confirm navigation with the user (see below)
    //getUserConfirmation: (message, callback) => callback(window.confirm(message))
});


const PrivateRouteNotAuth = ({component: Component, ...rest}) => {
    if(NoAuth() === true){
        localStorage.setItem('activeMenu','0');
        localStorage.removeItem('activeSubMenu');
    }

    return (
        <Router history={history}>
            <Route {...rest} render={(props) => (
                NoAuth() !== true
                    ? <Component {...props} />
                    : <Redirect to='/dashboard'/>
            )}/>
        </Router>
    );
};*/


const PrivateRouteNotAuth = ({component: Component, ...rest}) => {
    if(NoAuth() === true){
        localStorage.setItem('activeMenu','0');
        localStorage.removeItem('activeSubMenu');
    }
    return (

        <Route {...rest} render={(props) => (
            NoAuth() !== true
                ? <Component {...props} />
                : <Redirect to='/dashboard'/>
        )}/>
    );
};


export default PrivateRouteNotAuth;