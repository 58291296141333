
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { NavLink, useHistory, Route } from 'react-router-dom'
import axios from '../../axios';
import apiRoute from '../../apiRoute'
import getSession from '../../Component/UI/Session/getSession';
import destroySession from '../../Component/UI/Session/destroySession'
import Auxi from '../../hoc/Auxi';
import OtpVerification from '../../Component/NotAuth/OTP/OtpVerification';
import Layout from '../../Component/Layout/NotAuth';
import UserDeviceDeleteCheckPass from '../../Component/NotAuth/UserDevice/UserDeviceDeleteCheckPass';
import Header from '../../Component/Layout/Auth';
import createSession from '../../Component/UI/Session/createSession';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import CryptoJS from 'crypto-js';
import lifecycle from 'react-pure-lifecycle';
import Modal from '../../Component/UI/Modal/Modal';
import ModalContent from '../../Component/UI/Modal/ModalContent';
import { REQUEST_ANOTHER_OTP } from '../../utils/constants';

const methods = {
  componentDidMount(props) {
      console.log('I mounted! Here are my props:componentDidMount ', props);
  },
};

function UserDeviceRegistrationManager(p) {
    const [sendOtp, setSendOtp] =useState(false);
    const [modal, setModal] =useState(false);
    const [axiosStatus, setAxiosStatus] =useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [error, hasError] = useState(false);
    const [apiResponse, isApiResponse] = useState(true); 
    const [redirect, setRedirect] = useState(false);
    const [isDeviceRegistered, setIsDeviceRegistered] = useState(false);
    const [deviceRegError, setDeviceRegError] = useState(false);
    const [deletedDeviceId, setDeletedDeviceId] = useState('');
    let props;
    let deviceId;
    const history = useHistory();

    //deviceId Function starts here
    (async () => {
        try{
            const fpPromise = FingerprintJS.load();
            const fp = await fpPromise
            const result = await fp.get()
            deviceId = result.visitorId;
            console.log("visitorId from new component is",result.visitorId);
        }catch(e){console.warn(e)}       
        })()
    //deviceId dunctionends here

    if(!localStorage.getItem('userDeviceInfoData') && !p.fromDeviceListMenu){
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(p.location.state.sessionData), 'secret key 451').toString();
        // console.log("encrypted data is", encryptedData);
        localStorage.setItem('userDeviceInfoData', encryptedData);

        //localStorage.setItem('userDeviceInfoData', JSON.stringify(p.location.state.sessionData));    
        // localStorage.setItem('visitorId', JSON.stringify(p.location.state.visitorId));
    }


    if(p.deletedDeviceId || p.fromDeviceListMenu){
        props = {...p};
        console.log("called 1st");
    }else{
        props = p.location.state ? {...p.location.state} : {...p};
        console.log("called 2nd");
    }
    
    if(props.userDeviceInfo === undefined){
        // console.log("props.userDeviceInfo is", props.userDeviceInfo );
        console.log("localStorage.getItem is called");
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('userDeviceInfoData'), 'secret key 451');
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        console.log("decrypted data is", decryptedData);
        
        // props = {...JSON.parse(localStorage.getItem('userDeviceInfoData'))};
        props = {...decryptedData};
        
        props.userDeviceInfo = {
            ...props.userDevice
        };
        props.currentCountryCode = props.countryCode;
        props.currentMobile = props.mobileNo;
        // props.visitorId = JSON.parse(localStorage.getItem('visitorId'))
        props.visitorId = deviceId;
        console.log("props.visitorId after browser refresh", deviceId); 
    }

    //******this creates negative effect in the dashboard so paused ****/
    // useEffect(() => {   
    //     window.history.pushState(null, null, window.location.href);
    //     window.onpopstate = function () {
    //         history.go(1);
    //     };     
    // }, [])
    

    const spinnerHandler = ()=>{
        console.log("isLoading is called or not", isLoading);
        setIsLoading(!isLoading);
    };

    const handleOtpChange = (e, i) => {
        console.log("event is", e.target.value);
        const {value} = e.target;
        setOtp(value);
    };

    const registerDevice = () => {
        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };
            
        let deviceAxiosData ={
            userId: props.userId,
            // deviceId: props.visitorId,
            deviceId: deviceId,
            sessionId: props.sessionId
        };
        console.log("deviceAxiosData is", deviceAxiosData);
        
        axios.post(apiRoute.registerDevice, deviceAxiosData, {headers: authorization}).then(res => {
            if(res.status === 200){
                if(res.data.status === 200){
                    spinnerHandler();
                    console.log("successfull reponse from device registration");      
                    isApiResponse(true);
                    //create session after successful registration
                    console.log("create session called after device registration");
                    props.sessionData? createSession(props.sessionData) : createSession(props);
                    setIsDeviceRegistered(true);
                    localStorage.setItem('isUserDeviceInfoVarified',true);
                    setTimeout(() => {
                        setRedirect(true);
                    }, 1000);
                }else{
                    spinnerHandler();
                    // hasError(true);
                    hasError(false);
                    setDeviceRegError(res.data.details);
                    isApiResponse(true);
                    console.log("fail reponse from device registration", res.data);
                }
            }else{
                    spinnerHandler();
                    isApiResponse(false);
            }
        });
    }

    const verify = ()=>{
        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };
        spinnerHandler();

        let otpAxiosData ={
            purpose: apiRoute.otpPurpose.general,
            otp: otp,
            userId:  props.currentMobile,
        }
        axios.post(apiRoute.otp, otpAxiosData, {headers: authorization}).then(res => {
            if(res.status === 200){
                if(res.data.status === 200){
                    // spinnerHandler();
                    // console.log("successfull reponse from otp verify");
                    // setIsVerifiedOtp(true);
                    // hasError(false);
                    // isApiResponse(true);

                    registerDevice();
                }else{
                    spinnerHandler();
                    hasError(true);
                    setDeviceRegError(false);
                    isApiResponse(true);
                    console.log("fail reponse from otp verify", res.data);
                }
            }else{
                    spinnerHandler();
                    isApiResponse(false);
            }
        });
    };

    const submitDeviceRegistration = () => {
        spinnerHandler();
        setOtp('');
        // deviceRegError(false);
        let tokenType = getSession('token_type');
        let authToken = getSession('authToken');
        let authorization = { Authorization: ` ${tokenType} ${authToken}` };

        let axiosData = {
            countryCode: props.currentCountryCode,
            mobileNo: props.currentMobile,
            purpose: "general",
            channel: "sms",
        };
        console.log("axios data is", axiosData);
        console.log("userId is ", props.userId);

        axios.post(apiRoute.sendOtp,axiosData,{headers: authorization}).then(res => {
            console.log("response from api is", res.status);
            if(res.status === 200){
                if(res.data.status === 200){
                    console.log("successfully send otp");
                    spinnerHandler();
                    setSendOtp(true);
                    hasError(false);
                    isApiResponse(true);

                    if (res.data.code == REQUEST_ANOTHER_OTP){
                        setModal(true)
                        axiosStatusHandler(res.data.status, res.data.title, res.data.details)
                    }

                }else{
                    spinnerHandler();
                    hasError(true);
                    isApiResponse(true);
                    console.log("response data from send otp", res.data);
                    console.log("failure reponse from api");                 
                }
            }
            else{
                console.log("something went wrong in time of sending api");
                spinnerHandler();
                isApiResponse(false);
            }
        })
        
        
    }

    if(props.userDeviceInfo == undefined){
        console.log("called sign-in from return");
        return(
        <Route render={({ history}) => ( history.push('/sign-in') 
        )} />
        );
    }

    if(redirect){
        localStorage.removeItem('userDeviceInfoData');
        localStorage.setItem('isUserDeviceInfoVarified',true);
        return <Redirect to={'/dashboard'}/>
    }

    if(isDeviceRegistered){
        return(
            <Layout>
                <Auxi>
                    <h2 className="weight600 mt-3- mb-4 text-center">Successfully Registered Your  Device</h2>
                </Auxi>
            </Layout>

        )
    }

    /**
     * modal handler
     */
    const modalHandler = ()=> {
        setModal(!modal);
    };

    /**
     * axios handler
     * @param status
     * @param title
     * @param details
     */
    const axiosStatusHandler = (status, title, details)=>{
        let oldState = {};
        oldState.status = status;
        oldState.title = title;
        oldState.details = details;
        setAxiosStatus(oldState)
    };

    if(sendOtp){
        return (
            <Layout>

                <Modal show={modal} modalClosed={modalHandler}>
                    <ModalContent
                        status={axiosStatus.status}
                        title={axiosStatus.title}
                        details={axiosStatus.details}
                    />
                </Modal>

                <Auxi>
                    <OtpVerification
                        verify={verify}
                        stateChangeHandler={handleOtpChange}
                        otp={otp}
                        error={error}
                        apiResponse={apiResponse}
                        resendOtpHandler={submitDeviceRegistration}
                        changePhoneNumber={true} //***set this true bcz of the OtpVerification UI
                        deviceRegError={deviceRegError}
                    />
                </Auxi>
            </Layout>
        )
    }
    

    if(isLoading){
        return(
            <Auxi>
                {/* <Header> */}
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-md-8">
                            <div className="loader" style={{top: '0%'}}></div>
                            <h4 style={{"textAlign": "center"}}>this may take a few seconds....</h4>
                        </div>
                    </div>
                {/* </Header> */}
            </Auxi>
        );
    }
    
    if(props.deletedDeviceId || (props.userDeviceInfo && props.userDeviceInfo.isAvailable)){
        return (
        <Auxi>
            <Layout>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-md-8">
                            <div className="card">
                                <div className="card-body p-lg-5">
                                    <h2 className="weight600 mt-3- mb-4 text-center">Register Device</h2>
                                        
                                    <h4 className="weight300 mb-4 text-center">Do you want to register your current device?</h4>
                                    
                                        <div className="text-center mt-5">
                                                <button type="submit" onClick={submitDeviceRegistration} className="btn btn-primary btn-block">ADD THIS DEVICE</button>
                                        </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </Auxi>
            )
    }else{

        const logOut= () => {
            // const { from } =   { from : {pathname: '/', state:{}}};
            if(destroySession){
                history.push('/sign-in');
            }
        }
        const deleteDevice = (deviceId) => {
            console.log("device delete is called");
            setDeletedDeviceId(deviceId);
        }
        
        if(deletedDeviceId){
                return (
                    <Auxi>
                        <Layout>
                            <UserDeviceDeleteCheckPass 
                                // userId={props.userId}
                                // sessionId={props.sessionId}
                                {...props}
                                deviceId={deletedDeviceId}
                            />
                        </Layout>
                    </Auxi>
                )
        }else{ 
            const renderDeviceList = <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-6 col-md-8">
                    <div className="card">
                        <div className="card-body p-lg-5">
                            <h2 className="weight600 mt-3- mb-4 text-center">Registered Devices</h2>
                                
                            <ul className="list-group">
                                        {props.userDeviceInfo.devices.map(device => {
                                            return(                       
                                                <li className="d-flex justify-content-between list-group-item weight300">{device.device_name}<span onClick={()=> deleteDevice(device.device_id)} style={{color: 'red'}}><i className="fa fa-trash"/></span>
                                                </li>)    
                                        })}
                                        
                            </ul>
                            { props.userDeviceInfo.devices.length > 0 ?
                            <div className="text-center mt-5">
                                    <button type="submit" onClick={()=> deleteDevice("all")} className="btn btn-primary btn-block">Delete All Device</button>
                            </div>: null}
                            {
                                props.fromDeviceListMenu ? null :
                                <div className="text-center mt-2">
                                        <button type="submit" onClick={()=> logOut()} className="btn btn-primary btn-block">Logout</button>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>;

        if(props.fromDeviceListMenu){
            return (
                <Auxi>
                    <Header>
                        {renderDeviceList}
                    </Header>
                </Auxi>
            )
        }else{
            return (
                <Auxi>
                    <Layout>
                        {renderDeviceList}
                    </Layout>
                </Auxi>
            )
        }   
        }
        
    }
}


export default lifecycle(methods)(UserDeviceRegistrationManager);