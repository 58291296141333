let salts = {
    keys: [
        {
            "kty": process.env.REACT_APP_KTY,
            "kid": process.env.REACT_APP_KID,
            "use": process.env.REACT_APP_USE,
            "alg": process.env.REACT_APP_ALG,
            "k": process.env.REACT_APP_K
        }
    ]
};

module.exports = salts;