import axios from 'axios';
import jose from 'node-jose';
import apiRoute from './apiRoute';
import salts from './fetchSalts';
require('dotenv');

const instance = axios.create({
    baseURL : apiRoute.baseUrlAuth,
});

function encrypt(data){
    return new Promise((resolve, reject)=>{
        jose.JWK.asKeyStore(salts).then(result => {
            jose.JWE.createEncrypt({ format: 'compact', zip: true }, result.get(salts.keys[0].kid))
                .update(JSON.stringify(data))
                .final()
                .then(res => {
                    return resolve(res);
                }).catch((err) => {
                return reject(err);
            });
        });
    })
}


instance.interceptors.request.use( async req => {
        let data = '';
        data = await encrypt(req.data);
        req.data = { payload :data };
        return req;
    }, err => {
        return Promise.reject(err);
    }
);



instance.interceptors.response.use( async res => {
    return res;
}, err => {
    return Promise.reject(err);
});

export default instance;