import React from 'react';
import lyfecycle from 'react-pure-lifecycle';
import Auxi from '../../hoc/Auxi';
import Logo from '../../assets/logo.svg';
import LogoContainer from './Logo';
import SettingsContainer from './UserSettings';
import Footer from '../../Component/Layout/Footer';
import Aside from '../UI/Aside/Aside';

const method = {
    componentDidMount(){

        window.$('#nav-accordion').dcAccordion({
            eventType: 'click',
            autoClose: true,
            saveState: true,
            disableLink: true,
            speed: 'fast',
            showCount: false,
            autoExpand: true,
            cookie: 'dcjq-accordion-1',
            classExpand: 'dcjq-current-parent'
        });
            document.body.className= "left-sidebar-fixed left-sidebar-light header-fixed";
    },

};
const toggleNavBar = ()=>{
    if(!window.$('#toggleBar').hasClass('is-active')){
        document.body.className= "left-sidebar-fixed left-sidebar-light header-fixed left-nav-toggle";
        window.$('#toggleBar').addClass('is-active')
    }else{
        document.body.className= "left-sidebar-fixed left-sidebar-light header-fixed";
        window.$('#toggleBar').removeClass('is-active')

    }
};
const Auth = (props)=>{
    return(
        <Auxi>
            <header className="app-header">
                <div className="custom-header">

                        <LogoContainer Logo={Logo}/>

                        <ul className="header-links hide-arrow navbar">
                            {/* <NotificationContainer/>*/}

                            <SettingsContainer/>
                            <li className="nav-item" onClick={e => toggleNavBar()}>
                                {/*hamburger hamburger--slider js-hamburger js_left-nav-toggler is-active*/}
                                <div id="toggleBar" className="hamburger hamburger--slider js-hamburger js_left-nav-toggler" href="javaScript:;">
                                    <div className="hamburger-box">
                                        <div className="hamburger-inner"/>
                                    </div>
                                </div>
                            </li>
                        </ul>
                </div>



            </header>
            <div className="app-body">
                <Aside/>
                <div className="content-wrapper mt-5">
                    <div className="container-fluid">
                {/*<DemoAside/>*/}

                {props.children}
                    </div>
                <Footer/>
                </div>
            </div>
        </Auxi>
    )
};

export default lyfecycle(method)(Auth);